import axios from 'axios';
import API_URL from '../hooks/Config';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
// import SnackBar from '../components/SnackBar';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import ConfirmationDialog from '../components/ConfirmationDialog';
// import { quantityOnlyOperators } from '../components/Allocation/quantityOnlyOperators';
const ipms =['AA', 'LE', 'TB', 'ZP']

const readData = async (setRows) => {
    try {
        const response = await axios({
            url: `${API_URL}/IPM_Allocation/fetch_records`,
            method: 'GET',
        });
        const data = response.data.rows;
        console.log(data)

        // Ensure each row has a unique id
        const dataWithIds = data.map(item => {
            if (!item.id) {
                item.id = randomId();
            }
            return item;
        });
        const sortedData = dataWithIds.sort((a, b) => b.id - a.id);

        setRows(sortedData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


const addData = async (newRowData) => {
    // Define default values
    const defaultValues = {
        ipm: '',
        po_code: '',
        kr_pm: '',
        client: '',
        sales: 0,
        status: 'DTP',
        dtp_worker: '',
        dtp_deadline: new Date(),
        tr_worker: '',
        tr_deadline: new Date(),
        pr_worker: '',
        pr_deadline: new Date(),
        delivery: new Date(),
    };

    const completeRowData = { ...defaultValues, ...newRowData };

    for (const key in defaultValues) {

        if (newRowData[key] === undefined) {
            console.log("here", newRowData[key])
            completeRowData[key] = defaultValues[key];
        }
    }

    console.log(completeRowData)

    try {
        const response = await axios({
            url: `${API_URL}/IPM_Allocation/add_record`,
            method: 'POST',
            data: completeRowData,
        });
        
        // Backend response should ideally return the newly created row with its ID
        return response.data;
    } catch (error) {
        console.error("Error adding row", error);
        throw error;
    }
};

const updateData = async (id, updatedRowData) => {
    try {
        const response = await axios({
            url: `${API_URL}/IPM_Allocation/update_record/${id}`,
            method: 'PUT',
            data: updatedRowData,
        });

        return(response.data)


    } catch (error) {
        console.error('Error updating row:', error);
    }
};

const deleteData = async (id, setRows) => {
    try {
        await axios({
            url: `${API_URL}/IPM_Allocation/delete_record/${id}`,
            method: 'DELETE',
        });

        // Remove the row from the frontend after successful deletion
        setRows((oldRows) => oldRows.filter((row) => row.id !== id));

    } catch (error) {
        console.error('Error deleting row:', error);
    }
};

const randomId = () => {
    return Math.floor(Math.random() * 1000000);
};

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const newId = randomId();
        const newRow = {
            id: newId,
            ipm: 'AA',
            status: 'DTP',
            dtp_deadline: new Date(),
            tr_deadline: new Date(),
            pr_deadline: new Date(),
            delivery: new Date(),
            isNew: true,  // Mark row as new
        };
    
        // Add the new row in edit mode
        setRows((oldRows) => [newRow, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'IPM' },
            // Ensure that old modes are preserved
            ...Object.fromEntries(
                Object.keys(oldModel).filter((key) => key !== newId).map((key) => [key, oldModel[key]])
            ),
        }));
    };    

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}



////////////////////////////////////////////////////////////////
function IPM_Allocation () {
    // CONST
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});

    // USEEFFECT
    useEffect(() => {
        readData(setRows); //Read Data Here
    }, []);

    // CONST
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
    
    const handleDeleteClick = (id) => () => {
        deleteData(id, setRows);
        // setConfirmationOpen({ id: id, isOpen: true }); // Not Working
    };
    
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    
        const editedRow = rows.find((row) => row.id === id);

        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };


    const processRowUpdate = (newRow) => {
        console.log(newRow)
        if (newRow.isNew) {
            addData(newRow);
        } else {
            updateData(newRow.id ,newRow)
        }

        const updatedRow = { ...newRow, isNew: false };

        return updatedRow;
    };
    
    
    
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    // COLUMNS
    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        { 
            field: 'ipm', 
            headerName: 'IPM', 
            type: 'singleSelect',
            valueOptions: ipms,
            width: 95, 
            editable: true },
        { 
            field: 'po_code', 
            headerName: 'PO Code', 
            width: 150, 
            editable: true 
        },
        { 
            field: 'kr_pm', 
            headerName: 'KR PM',  
            width: 95, 
            editable: true },
        { 
            field: 'client', 
            headerName: 'Client',  
            width: 150, editable: true 
        },
        {
            field: 'sales',
            headerName: 'Sales (₩)',
            type: 'number',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            type: 'singleSelect', 
            valueOptions: ['DTP', 'TR', 'PR', 'Final QA' ,'Ready' ,'Submitted', 'Delivered' ], 
            width: 110, 
            editable: true 
        },
        { 
            field: 'dtp_worker', 
            headerName: 'DTP Worker',  
            width: 150, 
            editable: true 
        },
        {
            field: 'dtp_deadline',
            headerName: 'DTP Deadline',
            type: 'dateTime',
            width: 180,
            editable: true,
            valueFormatter:(params) => {
                const formattedDate = params.value.toLocaleString();
                return formattedDate;
            },
            valueGetter: (params) => {
                const chosen_date = new Date(params.value);
                return chosen_date;
            },
        },
        { 
            field: 'tr_worker', 
            headerName: 'TR Worker',  
            width: 100, 
            editable: true 
        },
        {
            field: 'tr_deadline',
            headerName: 'TR Deadline',
            type: 'dateTime',
            width: 180,
            editable: true,
            valueFormatter:(params) => {
                const formattedDate = params.value.toLocaleString();
                return formattedDate;
            },
            valueGetter: (params) => {
                const chosen_date = new Date(params.value);
                return chosen_date;
            },
        },
        { 
            field: 'pr_worker', 
            headerName: 'PR Worker',  
            width: 100, 
            editable: true 
        },
        {
            field: 'pr_deadline',
            headerName: 'PR Deadline',
            type: 'dateTime',
            width: 180,
            editable: true,
            valueFormatter:(params) => {
                const formattedDate = params.value.toLocaleString();
                return formattedDate;
            },
            valueGetter: (params) => {
                const chosen_date = new Date(params.value);
                return chosen_date;
            },
        },
        { 
            field: 'delivery', 
            headerName: 'Delivery',
            type: 'dateTime',
            width: 150, 
            editable: true,
            valueFormatter:(params) => {
                const formattedDate = params.value.toLocaleString();
                return formattedDate;
            },
            valueGetter: (params) => {
                const chosen_date = new Date(params.value);
                return chosen_date;
            },
        },

        // Actions Columns
        { 
            field: 'actions',
            type: 'actions',
            headerName: 'Actions', 
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                    return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        sx={{ color: 'primary.main', }}
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    return (
        <Box sx={{ width: '95%','& .actions': { color: 'text.secondary',}, '& .textPrimary': { color: 'text.primary',},}}>

            <h2>IPM's Allocation System (Test)</h2>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 25 }},
                }}
                pageSizeOptions={[25, 50, 100]}
                editMode="row"
                
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />


            {/* <SnackBar 
                open={snackBarOpen}
                message={snackBar.message}
                severetype={snackBar.sbtype}
                onClose={() => setSnackBarOpen(false)}
            /> */}


            {/* <ConfirmationDialog
              open={isConfirmationOpen.isOpen || false}
              onClose={() => setConfirmationOpen({id: 0, isOpen: false})}
              onConfirm={handleDeleteClick(isConfirmationOpen.id)}
              message="Are you sure you want to delete this record?"
            >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>Are you sure you want to delete this record?</DialogContent>
            </ConfirmationDialog> */}

        </Box>
    );
}

export default IPM_Allocation;