// import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton } from '@mui/material';
// import { List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

// const drawerWidth = 240;
// const navItems = [
//   { link: '/GlossaryExtraction', name: 'Glossary Extraction' },
//   { link: '/DeadlineCalculator', name: 'Deadline Calculator' },
//   { link: '/WordCount', name: 'Word Count' },
//   { link: '/WorkloadAllocation', name: 'Workload Allocation' },
//   { link: '/WorkloadTracker', name: 'Workload Tracker' },
//   { link: '/LanguageSeparator', name: 'Language Separator' }
// ];

// function ResponsiveAppBar(props) {
//   const { window } = props;
//   const [mobileOpen, setMobileOpen] = useState(false);

//   const handleDrawerToggle = () => {
//     setMobileOpen((prevState) => !prevState);
//   };

//   const drawer = (
//     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
//       <Typography variant="h6" sx={{ my: 2 }}>
//         Lexcode
//       </Typography>
//       <Divider />
//       <List>
//         {navItems.map((item) => (
//           <Link key={item.name} to={item.link}>
//             <ListItem disablePadding>
//               <ListItemButton sx={{ textAlign: 'center' }}>
//                 <ListItemText primary={item.name} />
//               </ListItemButton>
//             </ListItem>
//           </Link>
//         ))}
//       </List>
//     </Box>
//   );

//   const container = window !== undefined ? () => window().document.body : undefined;

//   return (
//     <Box>
//       <CssBaseline />
//       <AppBar component="nav" style={{
//         background: 'linear-gradient(90deg, #262364, #A82B69)',
//       }}>
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { md: 'none' } }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography
//             variant="h6"
//             component="div"
//             sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
//           >
//             <Link to={'/'} style={{ textDecoration: 'none', color: '#fff'}}>
//               Lexcode Work Automation System
//             </Link>
//           </Typography>
//           <Box sx={{ display: { xs: 'none', md: 'block' } }}>
//             {navItems.map((item) => (
//               <Link key={item.name} to={item.link}>
//                 <Button sx={{ color: '#fff' }}>
//                   {item.name}
//                 </Button>
//               </Link>
//             ))}
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <nav>
//         <Drawer
//           container={container}
//           variant="temporary"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{
//             keepMounted: true, // Better open performance on mobile.
//           }}
//           sx={{
//             display: { xs: 'block', md: 'none' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//           }}
//         >
//           {drawer}
//         </Drawer>
//       </nav>
//     </Box>
//   );
// }

// export default ResponsiveAppBar;


import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const navItems = [
  { link: '/GlossaryExtraction', name: 'Glossary Extraction' },
  { link: '/DeadlineCalculator', name: 'Deadline Calculator' },
  { link: '/LanguageSeparator', name: 'Language Separator' },
  { link: '/PptCharCount', name: 'Powerpoint Character Count'},
  {link: '/WhiteCellSeparator', name: 'RTF White Cell Separator'},
  { link: '/WorkloadAllocation', name: 'Workload Allocation' },
  { link: '/Dashboard', name: 'Allocation Dashboard'},
  { link: '/Editors', name: 'Editor Schedule' },
  { link: '/PMTool', name: 'File Tracker' },
  { link: '/WordCount', name: 'Word Count' },
];

const dropdown1Items = [
  { link: '/GlossaryExtraction', name: 'Glossary Extraction' },
  { link: '/DeadlineCalculator', name: 'Deadline Calculator' },
  { link: '/LanguageSeparator', name: 'Language Separator' },
  { link: '/PptCharCount', name: 'Powerpoint Character Count'},
  {link: '/WhiteCellSeparator', name: 'RTF White Cell Separator'},
  {link: '/IPMAllocation', name: 'IPM Allocation'},
  {link: '/IPMPMTool', name: 'IPM PM Tool'},
];

const dropdown2Items = [
  { link: '/WorkloadAllocation', name: 'Workload Allocation' },
  { link: '/Dashboard', name: 'Allocation Dashboard'},
  { link: '/Editors', name: 'Editor Schedule' },
  { link: '/EditorTasks', name: 'Editor Tasks' },
  { link: '/PMTool', name: 'File Tracker' },
  { link: '/WordCount', name: 'Word Count' },
];

function ResponsiveAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDropdown1Open = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleDropdown2Open = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Lexcode
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link key={item.name} to={item.link}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" style={{
        background: 'linear-gradient(90deg, #262364, #A82B69)',
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
          >
            <Link to={'/'} style={{ textDecoration: 'none', color: '#fff'}}>
              Lexcode Work Automation System
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button sx={{ color: '#fff' }} aria-controls="dropdown1-menu" aria-haspopup="true" onClick={handleDropdown1Open}>
              IPM System
            </Button>
            <Menu
              id="dropdown1-menu"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleDropdownClose}
            >
              {dropdown1Items.map((item) => (
                <MenuItem key={item.name} onClick={handleDropdownClose}>
                  <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {item.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
            <Button sx={{ color: '#fff' }} aria-controls="dropdown2-menu" aria-haspopup="true" onClick={handleDropdown2Open}>
              ATPE SYSTEM
            </Button>
            <Menu
              id="dropdown2-menu"
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={handleDropdownClose}
            >
              {dropdown2Items.map((item) => (
                <MenuItem key={item.name} onClick={handleDropdownClose}>
                  <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {item.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default ResponsiveAppBar;